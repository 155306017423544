<template>
  <b-card>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <from-name />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <email-text />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <host-name />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <smtp-port />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <user-name />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <password />
        </b-col>
        <b-col cols="12">
          <crypto />
        </b-col>
      </b-row>
      <b-button
        variant="danger"
        @click="submitForm"
      >
        Kaydet
      </b-button>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
import {
  BButton, BCard, BRow, BCol,
} from 'bootstrap-vue'
import FromName from '@/views/Core/Smtp/elements/From.vue'
import EmailText from '@/views/Core/Smtp/elements/Email.vue'
import HostName from '@/views/Core/Smtp/elements/Host.vue'
import SmtpPort from '@/views/Core/Smtp/elements/Port.vue'
import UserName from '@/views/Core/Smtp/elements/User.vue'
import Password from '@/views/Core/Smtp/elements/Password.vue'
import Crypto from '@/views/Core/Smtp/elements/Crypto.vue'

export default {
  name: 'SmtpForm',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    FromName,
    EmailText,
    HostName,
    SmtpPort,
    UserName,
    Password,
    Crypto,
    ValidationObserver,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['smtpConfig/dataItem']
    },
    saveStatus() {
      return this.$store.getters['smtpConfig/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    this.getDataItem()
    localize(this.locale)
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getDataItem() {
      this.$store.dispatch('smtpConfig/getDataItem')
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$store.dispatch('smtpConfig/saveData', this.dataItem)
        }
      })
    },
  },
}
</script>
